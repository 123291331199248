<template>
	<div class="sld_chat_right">
		<div class="right_tab">
			<template v-for="(item, index) in tabs" :key="item.categoryId">
				<el-tooltip effect="dark" :content="item.categoryName" placement="top-start">
					<div class="tab" :class="{ on: index == tabIndex }" @click="changeTab(item, index)">
						<span>{{ item.categoryName }}</span>
						<div class="tag" v-if="categoryIds[index] && categoryIds[index].length">{{ categoryIds[index].length }}</div>
					</div>
				</el-tooltip>
			</template>
		</div>

		<div class="right_container">
			<div class="top">
				<el-input class="custom-input" v-model="inputStudioName" @input="handleSearch" placeholder="搜索" prefix-icon="Search" clearable />
			</div>
			<div class="tab_group_box" v-if="tabs && tabs[tabIndex]">
				<div class="tab_group" :style="{ height: collapse ? '206px' : '526px' }">
					<el-scrollbar ref="tab_scroll_box" :height="collapse ? '180px' : '500px'">
						<div class="tab_list">
							<el-tree
								ref="categoryTree"
								:data="tabs[tabIndex].children"
								:props="treeProps"
								icon="ArrowRightBold"
								node-key="categoryId"
								show-checkbox
								@check="treeChange"
							>
								<template #default="{ node }">
									<span class="custom-tree-node">
										<span :class="{ second: node.level > 1 }">{{ node.label }}</span>
									</span>
								</template>
							</el-tree>
						</div>
					</el-scrollbar>
					<div class="tab_group_bottom" @click="handleCollapse">
						<el-icon><ArrowDownBold v-if="collapse" /><ArrowUpBold v-else /></el-icon>
					</div>
				</div>
				<div class="state_box"></div>
			</div>
			<div class="store_group_box">
				<div class="store_group" style="height: 0">
					<el-scrollbar>
						<div class="store_item" v-for="item in view_list" :key="item.storeId" @click="handleStudio(item)">
							<span class="check_icon" :class="{ active: item.active }"></span>
							<div class="header_img">
								<img :src="item.storeLogoUrl" alt="" />
							</div>
							<div class="content">
								<p class="store_name" :title="item.storeName">{{ item.storeName }}</p>
								<p class="store_info">
									<span>关注{{ item.followNumber }}</span>
									<span class="line">在售{{ item.onlineGoodsNumber }}件作品</span>
								</p>
							</div>
						</div>
					</el-scrollbar>
				</div>
			</div>
			<div class="right_bottom">
				<div class="left">
					<span class="dark">选中</span>
					<span>{{ selectArr.length }}</span>
				</div>
				<div class="custom-btn small shadow" @click="handleSubmit" :class="{ disabled: selectArr.length === 0 }">确认</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance, watch, nextTick, computed } from 'vue';
import loadingState from '@/components/loadingState';
import { pcUrl, sourceUrl } from '@/utils/config.js';
import { debounce } from 'lodash';
import { ElMessage } from 'element-plus';
export default {
	name: 'chatRightList',
	components: {
		// loadingState,
	},
	setup(props, { emit }) {
		const identity = localStorage.getItem('identity');
		const { proxy } = getCurrentInstance();
		const tabs = ref('');
		const tabIndex = ref(0); //当前选中
		// 获取分类列表
		const getCategory = async () => {
			return new Promise((resolve, reject) => {
				proxy.$get('v3/goods/admin/goods/category/categoryTreeData').then((res) => {
					if (res.state == 200) {
						res.data = res.data.map((e) => {
							if (e.children?.length === 1) {
								e.children = e.children[0].children;
							}
							return e;
						});
						tabs.value = res.data;
						resolve();
					} else {
						ElMessage.error(res.msg);
					}
				});
			});
		};
		// 树节点配置
		const treeProps = reactive({
			label: 'categoryName',
			children: 'children',
			class: 'customNodeClass',
		});
		const categoryIds = ref([]);
		// 分类参数
		const treeChange = (data, node) => {
			const { checkedKeys, checkedNodes } = node;
			const ids = checkedNodes.filter((e) => e.grade === 3).map((e) => e.categoryId);
			categoryIds.value[tabIndex.value] = ids;
			filter_list.value = [];
			inputStudioName.value = '';
			getStudio();
		};

		const changeTab = async (item, index) => {
			tabIndex.value = index;
			nextTick(() => {
				proxy.$refs.categoryTree.setCheckedKeys(categoryIds.value[tabIndex.value] || [], true);
			});
			// selectArr.value = [];
			// studio_list.value = [];
		};
		// 折叠分类
		const collapse = ref(true);
		const handleCollapse = () => {
			collapse.value = !collapse.value;
			nextTick(() => {
				proxy.$refs.tab_scroll_box.update();
			});
		};
		// 搜索
		const inputStudioName = ref('');
		const handleSearch = debounce(() => {
			if (inputStudioName.value === '') {
				filter_list.value = [];
			} else {
				getStudio(inputStudioName.value);
			}
		}, 300);
		// 工作室列表
		const selectArr = computed(() => {
			const new_list = [...studio_list.value, ...filter_list.value];
			let map = new Map();
			new_list.forEach((item) => {
				if (!map.has(item.storeId) && item.active) {
					map.set(item.storeId, item);
				}
			});
			return Array.from(map.values());
		});
		const filter_list = ref([]);
		const studio_list = ref([]);
		const view_list = computed(() => {
			if (inputStudioName.value) {
				return filter_list.value;
			} else {
				return studio_list.value;
			}
		});
		const getStudio = (str) => {
			const ids = categoryIds.value.flat().join(',');
			if (!ids && !str) {
				studio_list.value = [];
				return;
			}
			let params = {};
			if (str) {
				params = {
					pageSize: 1000,
					storeName: str,
				};
			} else {
				params = {
					goodsCategoryId3: ids,
					pageSize: 1000,
				};
			}
			proxy.$get('v3/seller/admin/store/storeList', params).then((res) => {
				if (res.state == 200) {
					const studioIds = new Map([...studio_list.value, ...filter_list.value].map((item) => [item.storeId, item]));
					// const studioIds = new Set(selectArr.value.map((item) => item.storeId));
					const new_list = res.data.list.map((e) => {
						// 判断是否存在
						if (!studioIds.has(e.storeId)) {
							e.active = true;
							return e;
						} else {
							return studioIds.get(e.storeId);
						}
					});
					new_list.sort((a, b) => +b.active - +a.active);
					if (str) {
						filter_list.value = new_list;
					} else {
						studio_list.value = new_list;
					}
					// studio_list.value = res.data.list.map((e) => {
					// 	e.active = true;
					// 	return e
					// });
				} else {
					ElMessage.error(res.msg);
				}
			});
		};
		// 确认选择的工作室
		const handleSubmit = () => {
			if (selectArr.value.length === 0) return;
			emit('selectStudio', selectArr.value);
		};
		const handleStudio = (item) => {
			item.active = !item.active;
		};
		onMounted(async () => {
			await getCategory();
		});

		return {
			identity,
			tabs,
			treeProps,
			tabIndex,
			changeTab,
			handleCollapse,
			inputStudioName,
			handleSearch,
			collapse,
			studio_list,
			selectArr,
			handleStudio,
			treeChange,
			categoryIds,
			handleSubmit,
			filter_list,
			view_list,
		};
	},
};
</script>

<style lang="scss" scoped>
.sld_chat_right {
	width: 287px;
	margin-left: 24px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	box-shadow: 2px 6px 12px 0px rgba(143, 141, 143, 0.3);
	border-radius: 0 0 10px 10px;

	.right_tab {
		display: flex;
		width: 100%;
		z-index: 2;
		border-bottom: 1px solid #b8b8b8;
		.tag {
			position: absolute;
			text-align: center;
			top: -10px;
			background-color: #505050;
			height: 20px;
			line-height: 20px;
			min-width: 20px;
			border-radius: 20px;
			font-size: 12px;
			transform: scale(0.85) translateX(-50%);
			color: #fff;
			left: 0;
			padding: 0 5px;
		}
		.tab {
			position: relative;
			transform: translateY(1px);
			font-size: 16px;
			line-height: 20px;
			color: #767676;
			background: #e7e7e7;
			height: 35px;
			line-height: 33px;
			cursor: pointer;
			border: 1px solid #b8b8b8;
			border-radius: 10px 10px 0 0;
			padding: 0 5px;
			width: calc(100% / 4 - 9px);
			span {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-align: center;
			}
			&.on {
				color: #000;
				background: #fff;
				border-bottom: 0;
			}
			&:not(:last-of-type) {
				margin-right: auto;
			}
		}
	}
	.right_container {
		flex: 1;
		border: 1px solid #b8b8b8;
		border-top: none;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: #fff;
		border-radius: 0 0 10px 10px;
		.top {
			padding: 15px 12px 0;
			.custom-input {
				box-sizing: border-box;
			}
		}
		.right_list_con {
			padding-top: 18px;
		}
		.store_group_box {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			.store_group {
				flex: 1;
			}
		}
	}
}
.store_group {
	.store_item {
		display: flex;
		align-items: center;
		margin: 0 15px;
		padding: 10px 5px;
		cursor: pointer;
		border-bottom: 1px solid #eeeeee;
		.header_img {
			flex-shrink: 0;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			overflow: hidden;
			margin: 0 8px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.content {
			flex: 1;
			overflow: hidden;
		}
		.store_name {
			color: #17171a;
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 6px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.store_info {
			display: flex;
			align-items: center;
			color: #8d8d99;
			line-height: 14px;
			.line {
				border-left: 1px solid #8d8d99;
				margin-left: 5px;
				padding-left: 5px;
			}
		}
	}
}
.tab_group_box {
	position: relative;
	margin-top: 10px;
	.state_box {
		height: 206px;
	}
	.tab_group {
		position: absolute;
		width: 100%;
		left: 0;
		transition: height 0.25s;
		display: flex;
		flex-direction: column;
		z-index: 2;
		background-color: #fff;
		.tab_list {
			padding: 0 20px 0 15px;
		}
		.tab_group_bottom {
			width: 100%;
			text-align: center;
			cursor: pointer;
			padding: 5px 0;
			border-bottom: 1px solid #eeeeee;
		}
	}
}

.empty_data {
	height: 300px;
	padding-top: 100px;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		margin-left: 10px;
		font-size: 12px;
		font-weight: 400;
		color: #d5d5d5;
	}
}
:deep(.customNodeClass) {
	.el-tree-node__content {
		height: auto;
		.el-checkbox__input {
			&.is-indeterminate {
				.el-checkbox__inner {
					opacity: 0.3;
				}
			}
			&.is-indeterminate,
			&.is-checked {
				.el-checkbox__inner {
					border-color: #505050;
					border-width: 4px;
				}
			}
			.el-checkbox__inner {
				width: 16px;
				height: 16px;
				overflow: hidden;
				border-radius: 100%;
				border-color: #b8b8b8;
				background-color: transparent;
				transition: all 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
			}
			.el-checkbox__inner {
				&::before,
				&::after {
					content: none;
				}
			}
		}
		&::before {
			content: '';
			display: inline-block;
			width: 5px;
		}
	}
	.el-icon {
		position: absolute;
		right: 0;
	}
}
.right_bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 20px;
	border-top: 1px solid #eeeeee;
	.left {
		letter-spacing: 2px;
		.dark {
			color: #a9a9b8;
		}
	}
}
.custom-tree-node {
	font-size: 14px;
	flex: 1;
	.second {
		color: #8d8d99;
	}
}
</style>
