<template>
	<div class="sld_chat_page">
		<chatLeftList :showTab="false" is_chat_room is_studio_room @switchMember="switchMember" ref="left" :connectBaseData="connectBaseData" />
		<div class="chat_zone">
			<chatWindow ref="chatWindow" is_chat_room is_studio_room @refreshLeftList="refreshLeftList"></chatWindow>
		</div>
		<studioRight @selectStudio="selectStudio"></studioRight>
	</div>
</template>
<script>
// import "element-plus/lib/index.full.js";
import 'element-plus/dist/index.full.js';
import chatWindow from '@/components/chatWindow';
import { ref, reactive, getCurrentInstance, watchEffect } from 'vue';
import chatLeftList from './chatLeftList';
import studioRight from './studioRight';
import { pcUrl, storeUrl, sourceUrl } from '@/utils/config';
export default {
	name: 'studioPage',
	components: {
		chatWindow,
		chatLeftList,
		studioRight,
	},
	beforeCreate() {},

	setup(props, { emit }) {
		// TODO【MD】seller
		const connectBaseData = {
			storeId: localStorage.identity == 'seller' ? localStorage.storeId : 0,
			userId: localStorage.identity == 'seller' ? localStorage.vendorId : localStorage.adminId,
			role: 2,
		};

		// const connectBaseData = { storeId: 4, userId: 4, role: 2 };
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const identity = localStorage.getItem('identity');
		let room_info = {};
		//切换会员需要重新获取聊天列表以及用户的信息
		const switchMember = (data) => {
			let window_instance = proxy.$refs.chatWindow;
			room_info.value = { ...data };
			window_instance.setId(data);
			emit('getCurMemberId', data);
			// proxy.$refs.right.getSwitch(memberId);
		};

		//获取订单，足迹，推荐商品，并发送
		const getObj = (data, type) => {
			let msgData = {};
			console.log(msgData, 'msgData');
			msgData.memberId = room_info.memberId;
			msgData.vendorId = room_info.vendorId;
			if (type == 'foot') {
				msgData.msgType = 3;
				msgData.msg = {
					productId: data.productId,
					goodsImage: data.goodsImage,
					goodsName: data.goodsName,
					goodsPrice: data.productPrice,
				};
			} else if (type == 'recom') {
				msgData.msgType = 3;
				msgData.msg = {
					productId: data.productId,
					goodsImage: data.mainImage,
					goodsName: data.goodsName,
					goodsPrice: data.goodsPrice,
				};
			} else if (type === 'order') {
				msgData.msgType = 4;
				msgData.msg = {
					orderSn: data.orderSn,
					createTime: data.createTime,
					orderStateValue: data.orderStateValue,
					productId: data.goodsItem.productId,
					goodsImage: data.goodsItem.productImage,
					goodsName: data.goodsItem.goodsName,
					goodsPrice: data.goodsItem.productShowPrice,
				};
			}
			proxy.$socket.emit('send_msg', { ...msgData, ...connectBaseData });
			//scrolltoNewMsg();
		};

		const refreshLeftList = () => {
			proxy.$refs.left.resetChatList();
		};
		const selectStudio = (data) => {
			// 选择工作室
			const storeIdList = data.map((e) => e.storeId);
			const memberName = data.map((e) => e.storeName).join(',');
			proxy.$refs.chatWindow.setId({ storeIdList, memberName });
		};
		watchEffect(() => {});
		// 消息提示音
		const play = () => {
			let audioElement = document.createElement('audio');
			let voice = require('@/assets/media/msg.mp3');
			audioElement.setAttribute('src', voice);
			audioElement.setAttribute('autoplay', 'autoplay');
		};

		return {
			switchMember,
			getObj,
			selectStudio,
			connectBaseData,
			refreshLeftList
		};
	},
};
</script>
<style lang="scss" scoped>
.sld_chat_page {
	flex-shrink: 0;
	width: 100%;
	height: 810px;
	display: flex;
	flex-shrink: 0;

	.chat_zone {
		flex: 1;
		height: 100%;
	}
}
</style>
